// Legacy version of the global.js script
// We need some polyfills for old browsers that doesn't support ES6 code
// ES6 Support : https://caniuse.com/es6
// Firebase requirements : https://firebase.google.com/docs/web/environments-js-sdk
import "regenerator-runtime/runtime";
import "core-js/stable";
import "cross-fetch/polyfill";
import "./polyfills/clipboardWriteText";

import "./global";
